<template>
  <div class="loading-container" :style="`height: ${height}px`">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'LoadingContainer',
  props: {
    height: {
      type: String,
      default: '200',
    },
  },
}
</script>
<style scoped>
.loading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto !important;
  margin-left: auto !important;
}
</style>
