export default {
  base64ToBlob: base64 => {
    const binaryString = window.atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    return new Blob([bytes], { type: 'application/pdf' })
  },

  uniqueObject: array => {
    return array.filter(
      (obj, index, arr) =>
        arr.findIndex(obj2 => JSON.stringify(obj) === JSON.stringify(obj2)) ===
        index
    )
  },
}
