<template id="exams">
  <div class="neutral">
    <PatientExams v-if="role == 'patient'" />
    <PartnerExams v-else-if="role == 'partner_place'" />
    <DoctorExams v-else />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import PatientExams from './patient'
import DoctorExams from './doctor'
import PartnerExams from './partner'

export default {
  name: 'exams',
  components: { PatientExams, DoctorExams, PartnerExams },
  computed: {
    ...mapState({
      role: state => state.data.user.role,
    }),
  },
}
</script>
