<template>
  <div>
    <LoadingContainer v-if="loading" />
    <v-data-table
      v-else
      ref="labOrdersTable"
      :headers="labOrdersTableHeaders"
      :disable-sort="$isMobile()"
      :items="labOrders"
      :items-per-page="15"
      show-expand
      :single-expand="false"
      item-key="id"
    >
      <template v-slot:expanded-item="{ headers, item: labItem }">
        <td :colspan="headers.length">
          <v-btn
            v-show="!isExternalReport"
            :disabled="labItem.selected.length == 0"
            text
            :loading="loadingOpenReports"
            color="secondary"
            class="text-capitalize mb-2"
            @click.prevent="openReports(labItem)"
          >
            <v-icon left> mdi-clipboard-text </v-icon>
            Visualizar exame(s) selecionado(s)
          </v-btn>
          <v-btn
            v-show="hasAllReport(labItem)"
            text
            color="secondary"
            class="text-capitalize mb-2"
            :loading="loadingConsolidated"
            @click.prevent="openReports(labItem, true)"
          >
            <v-icon left> mdi-clipboard-text </v-icon>
            Visualizar todos os exames
          </v-btn>
          <v-data-table
            ref="labExamsTable"
            :disable-sort="$isMobile()"
            :headers="labExamsTableHeaders"
            v-model="labItem.selected"
            :items="labItem.exams"
            disable-pagination
            hide-default-footer
            :show-select="!isExternalReport"
            :single-select="false"
            item-key="id"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                icon
                title="Visualizar Laudo"
                color="secondary"
                @click.prevent="openMedicalReport(item)"
                :disabled="item.lau_isn == null"
              >
                <v-icon size="21"> mdi-clipboard-text </v-icon>
              </v-btn>
            </template>
            <template v-slot:item.status="{ item }">
              <ImageExamStatusLabel :lau_isn="item.lau_isn" />
            </template>
            <template v-slot:item.external>
              <v-btn
                text
                color="secondary"
                class="text-capitalize"
                @click.prevent="openExternalReport(labItem.id)"
              >
                <v-icon left> mdi-clipboard-text </v-icon>
                Visualizar todos os resultados do pedido
              </v-btn>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import LabsMixin from '@/views/exams/labsMixin'
export default {
  name: 'PartnerLabExams',
  mixins: [LabsMixin],
}
// import Api from '@/resources/NajaSocialApi'
// import ImageExamStatusLabel from '@/views/exams/ImageExamStatusLabel'
// import LoadingContainer from '@/components/LoadingContainer'
// import { orderBy } from 'lodash'

// export default {
//   name: 'PatientLabExams',
//   components: { ImageExamStatusLabel, LoadingContainer },
//   computed: {
//     isExternalReport() {
//       const external = this.$store.state.data.organization.external_report
//       return external != null && external != ''
//     },

// labExamsTableHeaders() {
//   const headers = [
//     { field: 'name', value: 'name', text: 'EXAME', width: '40%' },
//     { field: 'date', value: 'date', text: 'DATA DO EXAME' },
//     { field: 'status', value: 'status', text: 'STATUS' },
//   ]

//   if (this.isExternalReport) {
//     headers.push({ field: 'external', value: 'external', sortable: false })
//   } else {
//     headers.unshift({ field: 'actions', value: 'actions', text: '' })
//   }

//   return headers
// },
//   },
//   data() {
//     return {
//       expanded: [],
//       loading: false,
//       labOrdersTableHeaders: [
//         { text: '', value: 'data-table-expand' },
//         { field: 'order', value: 'id', text: 'Nº DO PEDIDO' },
//         { field: 'date', value: 'date', text: 'DATA DE ATENDIMENTO' },
//         {
//           field: 'patient',
//           value: 'patient',
//           text: 'PACIENTE',
//         },
//         { field: 'branch', value: 'branch_name', text: 'EMPRESA' },
//       ],
//       labOrders: [],
//     }
//   },

//   methods: {
//     openExternalReport(order) {
//       if (
//         this.$store.state.data.organization.url_resultado_consolidado != null
//       ) {
//         const url =
//           this.$store.state.data.organization.url_resultado_consolidado.replace(
//             '[numero_pedido]',
//             order
//           )
//         window.open(url, '_blank')
//       }
//     },

//     search(params = {}) {
//       this.loading = true

//       Api.labExams(params)
//         .then(response => {
//           const orders = orderBy(
//             response.data.orders,
//             order => {
//               return this.$moment(order.date, 'DD/MM/YYYY')
//             },
//             ['desc']
//           )

//           orders.forEach(order => {
//             order.selected = []
//             order.patient =
//               order.exams.length == 0 ? null : order.exams[0].patient
//           })

//           this.labOrders = orders
//         })
//         .finally(() => {
//           this.loading = false
//         })
//     },

//     openReports(order) {
//       const ids = order.selected.map(e => e.id)
//       Api.medicalReportCombinedPDF(ids)
//         .then(response => {
//           const blob = new Blob([response.data], { type: 'application/pdf' })
//           const src = window.URL.createObjectURL(blob)

//           const windowReference = window.open()
//           windowReference.location = src
//         })
//         .catch(e => {
//           this.$toast.show('Erro ao buscar Laudo', 'warn')
//           throw e
//         })
//         .finally(() => {
//           this.loading = false
//         })
//     },

//     openMedicalReport(exam) {
//       Api.medicalReportPDF(exam.lau_isn)
//         .then(response => {
//           const blob = new Blob([response.data], { type: 'application/pdf' })
//           const src = window.URL.createObjectURL(blob)

//           const windowReference = window.open()
//           windowReference.location = src
//         })
//         .catch(error => {
//           this.$toast.show('Erro ao buscar Laudo', 'warn')
//           throw error
//         })
//         .finally(() => {
//           this.loading = false
//         })
//     },
//   },
// }
</script>
