import Api from '@/resources/NajaSocialApi'
import ImageExamStatusLabel from '@/views/exams/ImageExamStatusLabel'
import LoadingContainer from '@/components/LoadingContainer'
import CommonHelper from '@/helpers/common.js'
import { orderBy } from 'lodash'

const mixin = {
  components: { ImageExamStatusLabel, LoadingContainer },
  computed: {
    isExternalReport() {
      const external = this.$store.state.data.organization.external_report
      return external != null && external != ''
    },

    labExamsTableHeaders() {
      const headers = [
        { field: 'name', value: 'name', text: 'EXAME', width: '40%' },
        { field: 'date', value: 'date', text: 'DATA DO EXAME' },
        { field: 'status', value: 'status', text: 'STATUS' },
      ]

      if (this.isExternalReport) {
        headers.push({ field: 'external', value: 'external', sortable: false })
      } else {
        headers.unshift({ field: 'actions', value: 'actions', text: '' })
      }

      return headers
    },
  },
  data() {
    return {
      expanded: [],
      loadingConsolidated: false,
      loadingOpenReports: false,
      loading: false,
      labOrdersTableHeaders: [
        { text: '', value: 'data-table-expand' },
        { field: 'order', value: 'id', text: 'Nº DO PEDIDO' },
        { field: 'date', value: 'date', text: 'DATA DE ATENDIMENTO' },
        {
          field: 'patient',
          value: 'patient',
          text: 'PACIENTE',
        },
        { field: 'branch', value: 'branch_name', text: 'EMPRESA' },
      ],
      labOrders: [],
    }
  },

  methods: {
    hasAllReport(order) {
      return order.exams.every(
        e => e.lau_isn != null && e.lau_isn != 0 && e.lau_isn != '0'
      )
    },
    openAllExams(order) {
      this.loadingConsolidated = true
      const payload = {
        codigo_pedido: order.id,
        codigo_solicitante: order.doctor_id,
      }

      Api.medicalReportConsolidated(payload)
        .then(response => {
          const blob = CommonHelper.base64ToBlob(response.data.laudo)
          const src = window.URL.createObjectURL(blob)

          const windowReference = window.open()
          windowReference.location = src
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status == 500) {
              this.$toast.show('Erro ao buscar Laudo', 'error')
            } else if (error.response.status == 404) {
              this.openReports(order, 'loadingConsolidated')
            } else {
              this.$toast.show(error.response.data.error, 'warn')
            }
          } else {
            throw error
          }
        })
        .finally(() => {
          this.loadingConsolidated = false
        })
    },

    openExternalReport(order) {
      if (
        this.$store.state.data.organization.url_resultado_consolidado != null
      ) {
        const url =
          this.$store.state.data.organization.url_resultado_consolidado.replace(
            '[numero_pedido]',
            order
          )
        window.open(url, '_blank')
      }
    },

    search(params = {}) {
      this.loading = true

      Api.labExams(params)
        .then(response => {
          this.labOrders = this.parse(response.data.orders)
        })
        .finally(() => {
          this.loading = false
        })
    },

    openReports(order, all = false, loadingElement = null) {
      const setLoading = value =>
        loadingElement == null
          ? (this.loadingOpenReports = value)
          : (this[loadingElement] = value)

      setLoading(true)

      const ids = all
        ? order.exams.map(e => e.id)
        : order.selected.map(e => e.id)

      Api.medicalReportCombinedPDF(ids)
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const src = window.URL.createObjectURL(blob)

          const windowReference = window.open()
          windowReference.location = src
        })
        .catch(e => {
          this.$toast.show('Erro ao buscar Laudo', 'warn')
          throw e
        })
        .finally(() => {
          setLoading(false)
        })
    },

    parse(data) {
      const orders = orderBy(
        data,
        order => {
          return this.$moment(order.date, 'DD/MM/YYYY')
        },
        ['desc']
      )
      orders.forEach(order => {
        order.selected = []
        order.doctor_id =
          order.exams.length == 0 ? null : order.exams[0].doctor_requestor_id
        order.patient = order.exams.length == 0 ? null : order.exams[0].patient
      })

      return orders
    },

    openMedicalReport(exam) {
      Api.medicalReportPDF(exam.lau_isn)
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const src = window.URL.createObjectURL(blob)

          const windowReference = window.open()
          windowReference.location = src
        })
        .catch(error => {
          this.$toast.show('Erro ao buscar Laudo', 'warn')
          throw error
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}

export default mixin
