<template>
  <div class="status-label" :style="{ background: bgColor, width: width }">
    {{ status }}
  </div>
</template>

<script type="text/javascript">
export default {
  props: {
    // status: String,
    lau_isn: [Number, String],
    width: {
      type: String,
      default: '80px',
    },
  },

  computed: {
    status() {
      let text = ''
      if (this.lau_isn != null && this.lau_isn != 0 && this.lau_isn != '0') {
        text = 'Liberado'
      } else {
        text = 'Pendente'
      }

      return text
    },

    bgColor() {
      let color = '#F2F3F8'
      if (this.status.toLowerCase().includes('liberado')) {
        color = '#32C167'
      }

      if (this.status.toLowerCase().includes('pendente')) {
        color = '#FFCC00'
      }

      return color
    },
  },
}
</script>

<style lang="css" scoped>
.status-label {
  border-radius: 12px;
  padding: 2px;
  color: white;
  text-align: center;
  width: 76px;
  display: inline-block;
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: capitalize;
}
</style>
