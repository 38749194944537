<template>
  <div id="patient-exams">
    <v-card outlined class="box" style="min-height: 600px">
      <div class="pa-4">
        <v-row align="center" justify="center">
          <v-col>
            <v-tabs v-model="tab">
              <v-tab class="text-capitalize">Exames de Imagem</v-tab>
              <v-tab class="text-capitalize">Exames Laboratoriais</v-tab>
            </v-tabs>
          </v-col>
          <v-col class="text-right">
            <v-btn
              small
              text
              color="secondary"
              class="text-capitalize"
              @click.prevent="refresh()"
            >
              <v-icon left> mdi-refresh </v-icon> Atualizar
            </v-btn>
            <v-btn
              title="Home"
              small
              text
              color="secondary"
              class="text-capitalize"
              :to="{ path: '/' }"
            >
              <v-icon left>mdi-arrow-left</v-icon>
              Ir para Home
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <ImageTab ref="imageTab" />
        </v-tab-item>
        <v-tab-item>
          <LabTab ref="labTab" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import ImageTab from './widgets/images'
import LabTab from './widgets/labs'

export default {
  name: 'PatientExams',
  components: { ImageTab, LabTab },

  data() {
    return {
      expanded: [],
      tab: 0,
    }
  },

  mounted() {
    if (this.$route.query.tab != null) {
      if (this.$route.query.tab == 'images') {
        this.tab = 0
      }

      if (this.$route.query.tab == 'labs') {
        this.tab = 1
      }
    }
  },

  methods: {
    refresh() {
      if (this.tab == 0) {
        this.$refs.imageTab.search()
      } else {
        this.$refs.labTab.search()
      }
    },
  },
}
</script>

<style lang="css" scoped>
#patient-exams >>> .v-data-table__expanded {
  box-shadow: unset;
}

#patient-exams >>> .v-data-table__expanded > td {
  padding: 1rem;
}
</style>
