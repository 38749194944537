<template>
  <div>
    <LoadingContainer v-if="loadingImageExams" />
    <v-data-table
      v-else
      ref="imageExamsTable"
      :headers="imageExamsTableHeaders"
      :items="imageExams"
      :items-per-page="15"
    >
      <template v-slot:item.actions="{ item }">
        <div style="width: 70px" class="d-flex justify-space-between">
          <ReportDialog :lau-isn="item.lau_isn" :pad-isn="item.id">
            <template v-slot:open="{ open }">
              <v-btn
                @click="open"
                small
                icon
                color="secondary"
                title="Visualizar Laudo"
              >
                <v-icon size="21">mdi-clipboard-text</v-icon>
              </v-btn>
            </template>
          </ReportDialog>
          <v-btn
            small
            icon
            title="Images"
            color="secondary"
            @click.prevent="openPACS(item)"
          >
            <v-icon size="21"> mdi-image </v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <ImageExamStatusLabel :lau_isn="item.lau_isn" />
      </template>
      <template v-slot:item.date="{ item }">
        {{ item.date | njDate }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ImageExamStatusLabel from '@/views/exams/ImageExamStatusLabel'
import LoadingContainer from '@/components/LoadingContainer'
import ReportDialog from '@/components/ReportDialog'
import Api from '@/resources/NajaSocialApi'
import { orderBy } from 'lodash'
export default {
  name: 'PatientImageExams',
  components: { ImageExamStatusLabel, LoadingContainer, ReportDialog },

  data() {
    return {
      expanded: [],
      loadingImageExams: false,
      imageExamsTableHeaders: [
        { field: 'actions', value: 'actions', text: '' },
        { field: 'name', value: 'name', text: 'EXAME', width: '40%' },
        {
          field: 'doctor',
          value: 'doctor_requestor',
          text: 'MÉDICO SOLICITANTE',
        },
        { field: 'date', value: 'date', text: 'DATA DO EXAME' },
        { field: 'status', value: 'status', text: 'STATUS' },
      ],
      imageExams: [],
    }
  },

  mounted() {
    this.search()
  },

  methods: {
    reportExists(lauISN) {
      return lauISN != undefined && lauISN != null && lauISN != 0
    },

    openMedicalReport(exam) {
      if (this.reportExists(exam.lau_isn)) {
        if (this.mobile) {
          const url = Api.medicalReportURL(exam.lau_isn)
          window.open(url, '_blank')
        } else {
          this.$refs.reportModal.open(exam.lau_isn, exam.origin.pad_isn)
        }
      } else {
        this.$toast.show('Laudo ainda não liberado para visualização', 'warn')
      }
    },

    openPACS(exam) {
      if (this.loadingPACS) {
        this.$toast.show('PACS carregando ...', 'warn')
        return
      }

      this.loadingPACS = true

      const params = {
        pad_isn: this.$hashids.encode(exam.origin.pad_isn),
        pac_cod: exam.origin.pac_cod,
        emp_sigla: exam.origin.emp_sigla,
        pex_data: exam.origin.pex_data,
        sgr_codmoddicom: exam.origin.sgr_codmoddicom,
        emp_cod: exam.origin.ate_emp_cod,
        pac_datanasc: exam.origin.pac_datanasc,
        pac_nome: exam.origin.pac_nome,
      }

      const windowReference = window.open()

      Api.pacs(params)
        .then(response => {
          windowReference.location = response.data.url
        })
        .catch(error => {
          this.$toast.show('Erro ao carregar PACS', 'error')
          throw error
        })
        .finally(() => {
          this.loadingPACS = false
        })
    },

    search(params = {}) {
      this.loadingImageExams = true

      Api.imageExams(params)
        .then(response => {
          this.imageExams = orderBy(
            response.data.exams,
            exam => {
              return this.$moment(exam.date)
            },
            ['desc']
          )
        })
        .finally(() => {
          this.loadingImageExams = false
        })
    },
  },
}
</script>
