<template>
  <div id="patient-exams">
    <v-card outlined class="box" style="min-height: 600px">
      <div class="pa-4">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6">
            <v-tabs v-model="tab">
              <v-tab class="text-capitalize">Exames de Imagem</v-tab>
              <v-tab class="text-capitalize">Exames Laboratoriais</v-tab>
            </v-tabs>
          </v-col>
          <v-col class="text-right" cols="12" sm="6">
            <v-form @submit.prevent="search" class="d-flex align-center">
              <button type="submit" hidden name="button"></button>
              <v-text-field
                outlined
                label="Digite sua busca"
                append-icon="mdi-magnify"
                dense
                ref="search"
                hide-details
                autofocus
                v-model="searchTerm"
              ></v-text-field>
              <v-btn
                small
                text
                color="secondary"
                class="text-capitalize"
                @click.prevent="search()"
              >
                <v-icon left> mdi-refresh </v-icon> Atualizar
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </div>
      <v-tabs-items v-model="tab">
        <v-tab-item :eager="true">
          <ImageTab ref="imageTab" />
        </v-tab-item>
        <v-tab-item :eager="true">
          <LabTab ref="labTab" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import ImageTab from './widgets/images'
import LabTab from './widgets/labs'

export default {
  name: 'PatientExams',
  components: { ImageTab, LabTab },

  data() {
    return {
      expanded: [],
      tab: 0,
      searchTerm: null,
    }
  },

  mounted() {
    if (this.$route.query.tab != null) {
      if (this.$route.query.tab == 'images') {
        this.tab = 0
      }

      if (this.$route.query.tab == 'labs') {
        this.tab = 1
      }
    }
  },

  methods: {
    search() {
      const params = {
        by_name: this.searchTerm,
      }

      this.$refs.imageTab.search(params)
      this.$refs.labTab.search(params)
    },
  },
}
</script>

<style lang="css" scoped>
#patient-exams >>> .v-data-table__expanded {
  box-shadow: unset;
}

#patient-exams >>> .v-data-table__expanded > td {
  padding: 1rem;
}
</style>
