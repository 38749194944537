<template>
  <div>
    <LoadingContainer v-if="loading" />
    <v-data-table
      v-else
      ref="labOrdersTable"
      :headers="labOrdersTableHeaders"
      :disable-sort="$isMobile()"
      :items="labOrders"
      :items-per-page="15"
      show-expand
      :single-expand="false"
      item-key="orderKey"
    >
      <template v-slot:expanded-item="{ headers, item: labItem }">
        <td :colspan="headers.length">
          <v-btn
            v-show="!isExternalReport"
            :disabled="labItem.selected.length == 0"
            :loading="loadingOpenReports"
            text
            color="secondary"
            class="text-capitalize mb-2"
            @click.prevent="openReports(labItem)"
          >
            <v-icon left> mdi-clipboard-text </v-icon>
            Visualizar exame(s) selecionado(s)
          </v-btn>
          <v-btn
            v-show="hasAllReport(labItem)"
            text
            color="secondary"
            class="text-capitalize mb-2"
            :loading="loadingConsolidated"
            @click.prevent="openAllExams(labItem)"
          >
            <v-icon left> mdi-clipboard-text </v-icon>
            Visualizar todos os exames
          </v-btn>
          <v-data-table
            ref="labExamsTable"
            :disable-sort="$isMobile()"
            :headers="labExamsTableHeaders"
            v-model="labItem.selected"
            :items="labItem.exams"
            disable-pagination
            hide-default-footer
            :show-select="!isExternalReport"
            :single-select="false"
            item-key="id"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                icon
                title="Visualizar Laudo"
                color="secondary"
                :disabled="item.lau_isn == null"
                @click.prevent="openMedicalReport(item)"
              >
                <v-icon size="21"> mdi-clipboard-text </v-icon>
              </v-btn>
            </template>
            <template v-slot:item.status="{ item }">
              <ImageExamStatusLabel :lau_isn="item.lau_isn" />
            </template>
            <template v-slot:item.external>
              <v-btn
                text
                color="secondary"
                class="text-capitalize"
                @click.prevent="openExternalReport(labItem.id)"
              >
                <v-icon left> mdi-clipboard-text </v-icon>
                Visualizar todos os resultados do pedido
              </v-btn>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import LabsMixin from '@/views/exams/labsMixin'
import { orderBy } from 'lodash'
import CommonHelper from '@/helpers/common'

export default {
  name: 'PatientLabExams',
  mixins: [LabsMixin],

  mounted() {
    this.search()
  },

  data() {
    return {
      labOrdersTableHeaders: [
        { text: '', value: 'data-table-expand' },
        { field: 'order', value: 'id', text: 'Nº DO PEDIDO' },
        { field: 'date', value: 'date', text: 'DATA DE ATENDIMENTO' },
        {
          field: 'doctor',
          value: 'doctor',
          text: 'MÉDICO SOLICITANTE',
        },
        { field: 'branch', value: 'branch', text: 'EMPRESA' },
      ],
    }
  },

  methods: {
    parse(data) {
      const source = orderBy(
        data,
        order => {
          return this.$moment(order.date, 'DD/MM/YYYY')
        },
        ['desc']
      )
      const orders = []

      source.forEach(order => {
        const doctors = CommonHelper.uniqueObject(
          order.exams.map(e => {
            return {
              name: e.doctor_requestor,
              id: e.doctor_requestor_id,
            }
          })
        )

        order.requests = []

        doctors.forEach((doctor, doctorIndex) => {
          const exams = order.exams.filter(
            e => e.doctor_requestor_id == doctor.id
          )

          const obj = {
            orderKey: `${order.id}-doctor-${doctorIndex}`,
            selected: [],
            id: order.id,
            branch: order.branch_name,
            doctor: doctor.name,
            doctor_id: doctor.id,
            date: order.date,
            exams: exams,
          }

          orders.push(obj)
        })
      })

      return orders
    },
  },
}
</script>
